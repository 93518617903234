import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const apiUrl=environment.baseUrl + '/Account/'
const apiUrl2=environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class RoleInfoService {


  constructor(private http: HttpClient) { }

  getUserList(): Observable<any> {
    return this.http.get(apiUrl + 'GetUserList');
  }

  getList(): Observable<any> {

    return this.http.get(apiUrl + 'UserRoleCreate');
  }

  save(model: any){
     return this.http.post(apiUrl +'UserIdentityRoleCreate' , model);
  }

  updateRoleAssign(model: any){
    return this.http.post(apiUrl +'updateRoleAssign' , model);
 }

 ResetPassword(model: any){
  return this.http.post(apiUrl +'UserResetPassword' , model);
}

ChangePassword(model: any){
  return this.http.post(apiUrl +'UserChangePassword' , model);
}
GetAllRole(rolename: string): Observable<any> {
  return this.http.get(apiUrl2 + '/Auth/GetAllRole', { params: { rolename: rolename } });
}



  //  delete(id: any){
  //    return this.http.delete(apiUrl + 'DeleteRole/'+ id );
  // }
}


import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {HttpBackend, HttpClient} from '@angular/common/http'
import { environment } from 'environments/environment';
import { LoginViewModel } from 'app/LoginViewModel';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EmailValidator } from '@angular/forms';
const apiUrl = environment.baseUrl;

@Injectable()
export class AuthService {
  private http : HttpClient;
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  private username: string = "";

  constructor( private https : HttpClient,public _firebaseAuth: AngularFireAuth, public router: Router, private httpBackend: HttpBackend,private jwtHelperService: JwtHelperService) {
    
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );

  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  // signinUser(email: string, password: string) {
  //   //your code for checking credentials and getting tokens for for signing in user
  //   return this._firebaseAuth.signInWithEmailAndPassword(email, password) 
  // }

  signinUser(name: string, password: string) {
    const requestBody = {
      name: name,
      password: password
    };
    return this.http.post(apiUrl + '/Account/Login', requestBody);
  }
  setUsername(username: string): void {
    this.username = username;
  }

  getUsername(): string | null {
    return this.username;
  }
  
  currentUserName: string  = null;
  currentUserRole: string  = null;
  currentUserEmail: string  = null;

  public Login(loginViewModel: LoginViewModel): Observable<any>
  {

     this.http = new HttpClient(this.httpBackend);
     console.log(this.httpBackend)
    return this.http.post<any>(apiUrl+'/Account/Login', loginViewModel, { responseType: "json", observe: "response" })
    .pipe(map(response => {
      if (response)
      {
        this.currentUserName = response.body.userInfo.userName;
        this.currentUserRole = response.body.role;
        sessionStorage.currentUser = JSON.stringify(response.body);
        localStorage.setItem('roleName', this.currentUserRole);
      }
      return response.body;
    }));
  }


  public logout() {
    sessionStorage.removeItem("currentUser");
    this.currentUserName = null;
    this.router.navigate([ "pages/login" ]);
    
  }

  public isAuthenticated() : boolean
  {
    var token = sessionStorage.getItem("currentUser")? JSON.parse(sessionStorage.getItem("currentUser")).jwt : null;
    if (this.jwtHelperService.isTokenExpired())
    {
      return false; //token is not valid
    }
    else
    {
      return true; //token is valid
    }
  }

  
  otpcode(otpViewMOdel: any) {

    var data=this.https.post(apiUrl + '/ForgotPassword/otpcode', otpViewMOdel);
    console.log('12121212',data);
    return data;
  }


  otpInfo(model: any) {

    return this.https.post(apiUrl + '/ForgotPassword/otpInfo', model);
  }

  changePassword(model: any) {
    return this.https.post(apiUrl + '/ForgotPassword/Confirmpassword', model);
  }


  
  // otpcode (model: any) 
  // { 
  //   return this.http.post(apiUrl + '/api/CashManagement/SaveDenomination', model);
  // }

  emailforotp(email: string) {
    
  }
}

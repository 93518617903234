import { CanActivateGuardService } from 'app/can-activate-guard.service';
import { RouteInfo ,RouteInfoEmp, RouteInfoForGeneralManager } from './vertical-menu.metadata';

export const ROUTESEMP: RouteInfoEmp[] = [

  {
    path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub',isExternalLink: false, submenu: [
      { path: '/dashboard/dashboard1', title: 'Dashboard', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []  },
      //{ path: '/dashboard/dashboard2', title: 'Dashboard 2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'HRM', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/hrm/employee-info/0', title: 'Employee Info', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/hrm/locationwise-designation', title: 'LocationWise Designation', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },


  {
    path: '', title: 'Loan Application', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     //{ path: '/loan/loan-application', title: 'Term Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan/lead-generation', title: 'Term Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan-application/demand-loan', title: 'Demand Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan-application/continuous-loan', title: 'Continuous Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan-application/micro-service-loan', title: 'Micro Service', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan/pending-cc-lead-loan', title: 'Pending CCLoan list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan/approve-cc-lead-loan', title: 'Approve CCLoan list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '', title: 'Client Edit', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     { path: '/loan-application/client-list', title: 'client list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'Lead Edit', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     { path: '/loan/lead-list', title: 'Lead list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'Approver Lead', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     { path: '/loan/approver-lead', title: 'Approver Lead', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },

  {
    path: '', title: 'Auth Master', icon: 'ft-lock', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/auth-master/role-info', title: 'Role', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/auth-master/users-edit', title: 'Users', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }

    ]
  },



];



export const ROUTESFORGENERALUSER: RouteInfoForGeneralManager[] = [

  {
    path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/dashboard/dashboard1', title: 'Dashboard', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []  },
      //{ path: '/dashboard/dashboard2', title: 'Dashboard 2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Approver Lead', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     { path: '/loan/approver-lead', title: 'Approver Lead', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '', title: 'Loan Application', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/loan/CC-lead-generation', title: 'CC Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/loan/pending-cc-lead-loan', title: 'Pending CCLoan list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },





];

export const ROUTESFORRelationshipManager: RouteInfoForGeneralManager[] = [

  {
    path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/dashboard/dashboard1', title: 'Dashboard', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []  },
      //{ path: '/dashboard/dashboard2', title: 'Dashboard 2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Approver Lead', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     { path: '/loan/approver-lead', title: 'Approver Lead', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '', title: 'Loan Application', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [

      { path: '/loan/lead-generation', title: 'Term Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/loan/CC-lead-generation', title: 'CC Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    { path: '/loan/ccloan-List', title: 'Ongoing CCLoan list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/loan/pending-cc-lead-loan', title: 'Pending CCLoan list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/loan/approve-cc-lead-loan', title: 'Approve CCLoan list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },




];




//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/dashboard/dashboard1', title: 'Dashboard', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //{ path: '/dashboard/dashboard2', title: 'Dashboard 2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'HRM', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/hrm/employee-info/0', title: 'Employee Info', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      /* {
        path: '/datatables', title: 'Data Tables', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      }, */
      { path: '/hrm/locationwise-designation', title: 'LocationWise Designation', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },

  {
    path: '', title: 'Loan', icon: 'ft-layers', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/loan/loan-type', title: 'Loan Type', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/loan/loan-category', title: 'Loan Category', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/loan/loan-product', title: 'Loan Product', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/loan/loan-sub-category', title: 'Loan Sub Category', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/loan/lead-document-type', title: ' Document Type', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '', title: 'Loan Application', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     //{ path: '/loan/loan-application', title: 'Term Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan/CC-lead-generation', title: 'CC Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan/lead-generation', title: 'Term Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan-application/demand-loan', title: 'Demand Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan-application/continuous-loan', title: 'Continuous Loan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan-application/micro-service-loan', title: 'Micro Service', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan/ccloan-List', title: 'Ongoing CCLoan list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan/pending-cc-lead-loan', title: 'Pending CCLoan list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan/approve-cc-lead-loan', title: 'Approve CCLoan list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'Client Edit', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     { path: '/loan-application/client-list', title: 'client list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'Lead Edit', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     { path: '/loan/lead-list', title: 'Lead list', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },








  {
    path: '', title: 'Approver Lead', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     { path: '/loan/approver-lead', title: 'Approver Lead', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     { path: '/loan/final-complete-lead', title: 'Final Complete Lead', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'Master Data', icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/master-data/location-type', title: 'Location Type', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/master-data/location', title: 'Location', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/master-data/desk', title: 'Desk', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      { path: '/master-data/circle', title: 'Circle Info', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      {path:'/master-data/zone',title:'Zone Info',icon:'ft-arrow-right submenu-icon',class:'',badge:'',badgeClass:'',isExternalLink:false,submenu:[]},
      {path:'/master-data/branch-info',title:'Branch Info',icon:'ft-arrow-right submenu-icon',class:'',badge:'',badgeClass:'',isExternalLink:false,submenu:[]},
      { path: '/master-data/agent', title: 'Agent Info', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/master-data/designation', title: 'Designation ', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/master-data/deskProductMappingComponent', title: 'Desk Product Mapping Component ', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/master-data/employee-product-mapping', title: 'Employee Product Mapping Component ', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/loan/delegation-of-power', title: 'Delegation Of Power', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }

    ]

  },
  {
    path: '', title: 'Auth Master', icon: 'ft-lock', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/auth-master/role-info', title: 'Role', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/auth-master/users-edit', title: 'Users', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }

    ]
  },
  {
    path: '', title: 'Navbar', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/navbars/navbar-info', title: 'Navbar Info', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/navbars/module', title: 'Module Info', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },



];



import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateGuardService implements CanActivate
{
  constructor(private loginService : AuthService, private router : Router)
  {
  }

  canActivate(route: ActivatedRouteSnapshot) : boolean
  {
    console.log(this.router.url);
    if (this.loginService.isAuthenticated())
    {
      return true; //the user can navigate to the particular route
    }
    else
    {
      this.router.navigate([ "pages/login" ]);
      return false; //the user can't navigate to the particular route
    }
  }
}
